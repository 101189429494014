<template>
    <div>
        <!-- Company Filters -->    
        <div class="vx-row">
            <div class="vx-col w-full mt-8">
                <vx-card>
                    <div class="card-title">
                        <h2>{{ LabelConstant.headerLabelCompanyFilters }}</h2>
                        <!-- <vs-button @click="helpPopupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn" ></vs-button> -->
                    </div>
                    <p class="mb-4">{{ LabelConstant.infoLabelFilterCompanies }}</p>
                    <p class="text-secondary mb-8">{{ LabelConstant.infoLabelFilerCompaniesProcess }}</p>
                    <!-- Tab -->
                    <div class="watch-list filter-list">
                        <vs-tabs>
                            <vs-tab :label="LabelConstant.tabLabelKnownISPEntityList" icon-pack="feather" icon="icon-list">
                                <div class="tab-text">                                    
                                    <vs-table ref="tableDataISPEntity" :data="knownISPEntityList" search :max-items="perPage" pagination>
                                        <div slot="header" class="flex justify-between">
                                            <vs-button @click="createKnownIpsPopup=true"  type="filled" icon-pack="feather" icon="icon-plus" class="bg-actionbutton small-btn mr-0 md:mr-4 w-full md:w-auto mb-2">
                                                {{ LabelConstant.buttonLabelAddaKnownISPEntity }}
                                            </vs-button>
                                            <vs-button @click="createBulkKnownIpsPopup=true" color="primary" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn w-full md:w-auto mb-2">
                                                {{ LabelConstant.buttonLabelAddaKnownISPInBulk }}
                                            </vs-button>
                                        </div>
                                        <template slot="thead">                
                                            <vs-th></vs-th>
                                            <vs-th sort-key="ISP_Name">{{ LabelConstant.tabelHeaderLabelKnownISPName }}</vs-th>
                                            <vs-th sort-key="Match_Type">{{ LabelConstant.tabelHeaderLabelMatchType }}</vs-th>
                                            <vs-th sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>                                
                                        </template>
                                        <template slot-scope="{data}">
                                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                <vs-td width="50">
                                                    <vs-button :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"
                                                        @click="deleteKnownIspPopupActive=true, deletableKnownIspId = tr.Id, deletableKnownIsp = tr.ISP_Name, deletedKnownIspMatchType = tr.Match_Type"
                                                    ></vs-button> 
                                                </vs-td>
                                                <vs-td :data="tr.ISP_Name">{{tr.ISP_Name}}</vs-td>
                                                <vs-td :data="tr.Match_Type">
                                                    <vs-chip color="success" v-if="tr.Match_Type == 'exact'">{{ tr.Match_Type }}</vs-chip>
                                                    <vs-chip color="warning" v-if="tr.Match_Type == 'contains'">{{ tr.Match_Type }}</vs-chip>
                                                </vs-td>
                                                <vs-td :data="tr.Date_Created">{{tr.Date_Created}}</vs-td>                                   
                                            </vs-tr>
                                        </template>
                                    </vs-table>
                                    <div class="table-row-select w-full py-0 mb-2">
                                        <vs-select :label="LabelConstant.inputLabelShowEntries" v-model="perPage" class="show-select" autocomplete>
                                        <vs-select-item
                                            :key="index"
                                            :value="item.value"
                                            :text="item.text"
                                            v-for="(item,index) in recordList"
                                        />
                                        </vs-select>
                                    </div>
                                    <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{knownISPEntityList.length|tableRecordNumberFormatter}} entries</p>
                                    <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{knownISPEntityList.length|tableRecordNumberFormatter}} entries)</p>
                                    <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
                                </div>
                            </vs-tab>
                            <vs-tab :label="LabelConstant.tabLabelKnownBusinessEntityList" icon-pack="feather" icon="icon-list">
                                <div class="tab-text">                                   
                                    <vs-table ref="tableDataBusinessEntity" :data="knownBusinessEntityList" search :max-items="perPage" pagination>
                                        <div slot="header" class="flex justify-between">
                                            <vs-button @click="createKnownBusinessPopup=true" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mr-0 md:mr-4 w-full md:w-auto mb-2 bg-actionbutton">
                                                {{ LabelConstant.buttonLabelAddaKnownBusinessEntity }}
                                            </vs-button>
                                            <vs-button @click="createBulkKnownBusinessPopup=true" color="primary" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn w-full md:w-auto mb-2">
                                                {{ LabelConstant.buttonLabelAddaKnownBusinessInBulk }}
                                            </vs-button>
                                        </div>
                                        <template slot="thead">                
                                            <vs-th></vs-th>
                                            <vs-th sort-key="ISP_Name">{{ LabelConstant.tabelHeaderLabelKnownBusinessName }}</vs-th>
                                            <vs-th sort-key="Match_Type">{{ LabelConstant.tabelHeaderLabelMatchType }}</vs-th>
                                            <vs-th sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>                                
                                        </template>
                                        <template slot-scope="{data}">
                                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                <vs-td width="50">
                                                    <vs-button :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"
                                                        @click="deleteKnownBusinessPopupActive=true, deletableKnownBusinessId = tr.Id, deletableKnownBusiness = tr.ISP_Name, deletedKnownBusinessMatchType = tr.Match_Type" 
                                                    ></vs-button> 
                                                </vs-td>
                                                <vs-td :data="tr.ISP_Name">{{tr.ISP_Name}}</vs-td>
                                                <vs-td :data="tr.Match_Type">
                                                    <vs-chip color="success" v-if="tr.Match_Type == 'exact'">{{ tr.Match_Type }}</vs-chip>
                                                    <vs-chip color="warning" v-if="tr.Match_Type == 'contains'">{{ tr.Match_Type }}</vs-chip>
                                                </vs-td>
                                                <vs-td :data="tr.Date_Created">{{tr.Date_Created}}</vs-td>                                   
                                            </vs-tr>
                                        </template>
                                    </vs-table>
                                    <div class="table-row-select w-full py-0 mb-2">
                                        <vs-select :label="LabelConstant.inputLabelShowEntries" v-model="perPage" class="show-select" autocomplete>
                                        <vs-select-item
                                            :key="index"
                                            :value="item.value"
                                            :text="item.text"
                                            v-for="(item,index) in recordList"
                                        />
                                        </vs-select>
                                    </div>
                                    <!-- <p v-if="!isSearchExclusionList && isTableRecordEntriesDisplayExclusionList">Showing {{showingDataFromExclusionList}} to {{showingDataToExclusionList}} of {{knownBusinessEntityList.length}} entries</p>
                                    <p v-if="isSearchExclusionList && isTableRecordEntriesDisplayExclusionList">Showing {{showingDataFromExclusionList}} to {{showingSearchedDataToExclusionList}} of {{totalSearchedDataExclusionList}} entries (filtered from {{knownBusinessEntityList.length}} entries)</p>
                                    <p v-if="!isTableRecordEntriesDisplayExclusionList">Showing 0 to 0 of 0 entries </p> -->
                                </div>
                            </vs-tab>
                        </vs-tabs>  
                    </div>   
                </vx-card>
            </div>
        </div>
        <!-- Help popup -->
        <help-modal :popupActive='helpPopupActive' @close="closepopup" ></help-modal>
        <!-- Create Known IPS Entry -->
        <vs-popup :title="LabelConstant.popupTitleCreateNewKnownISPEntry" :active.sync="createKnownIpsPopup"> 
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-6">{{ LabelConstant.infoLabelFilterCompaniesCreateAKnownISPEntity }}</p>
                </div>
            </div>
            <template>
                <form data-vv-scope="createKnownIpsForm">
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <span class="vs-input--label">{{ LabelConstant.inputLabelSelectMatchType }}</span>
                            <v-select
                                v-model="IspMatchType"
                                :reduce="matchTypeOptions => matchTypeOptions.value"
                                :label="'text'"
                                :options="matchTypeOptions"
                                :placeholder="LabelConstant.inputLabelSelectMatchType"
                                :clearable="false"
                            /> 
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" :label="LabelConstant.inputLabelCompanyName" v-model="IspCompanyName" name="IspCompanyName" v-validate="'required'"/>
                            <span class="text-danger text-sm">{{ errors.first('createKnownIpsForm.IspCompanyName') }}</span>
                            <p class="ins-text">{{ LabelConstant.inputinfoLabelSeletMatchType }}</p>
                        </div>
                    </div>
                </form>
            </template>
            <div class="float-right mt-4  mb-8 w-full sm:w-auto">            
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="addISPEntry()">{{ LabelConstant.buttonLabelCreateKnownISPEntry }}</vs-button>
                <vs-button @click="cancelCreateNewISP()"  color="grey" type="border" class="grey-btn ml-0 sm:ml-4 w-full sm:w-auto mt-2" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Delete Known IPS Entry? -->
        <vs-popup class="delete-popup" :active.sync="deleteKnownIspPopupActive"> 
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon"><feather-icon icon="Trash2Icon"></feather-icon></div>                    
                        <h4>{{ LabelConstant.popupHeadingLabelDeleteKnownISPEntry }}</h4>
                        <span class="deleted-no block">{{deletableKnownIsp}}</span>
                        <span class="deleted-no">{{ LabelConstant.inputLabelMatchType }}: {{deletedKnownIspMatchType}}</span>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button color="danger" type="filled" @click="deleteISP()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="cancelDeletionISP()"  color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup> 
        <!-- Create Known IPS Entry Bulk -->
        <vs-popup :title="LabelConstant.popupTitleCreateNewKnownISPEntriesInBulk" :active.sync="createBulkKnownIpsPopup"> 
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-6">{{ LabelConstant.infoLabelCreateNewKnownISPEntriesInBulk }}</p>
                </div>
            </div>
            <template>
                <form data-vv-scope="createBulkKnownIpsForm">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <span class="vs-input--label">{{ LabelConstant.inputLabelPleaseEnterKnownISPs }}</span>
                            <vs-textarea v-model="bulkIspCompanyNames" class="mb-0" name="bulkIspCompanyNames" v-validate="'required'"/>
                            <p class="ins-text">{{ LabelConstant.inputinfoLabelSeletMatchType }}</p>
                        </div>
                    </div>
                    <span class="text-danger text-sm">{{ errors.first('createBulkKnownIpsForm.bulkIspCompanyNames') }}</span>
                </form>
            </template>
            <div class="float-right mt-4 mb-8 w-full sm:w-auto">
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="addISPBulkEntry()">{{ LabelConstant.buttonLabelCreateKnownISPEntry }}</vs-button>
                <vs-button @click="cancelCreateNewBulkISP()"  color="grey" type="border" class="ml-0 sm:ml-4 w-full sm:w-auto mt-2 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Create Known Business Entry -->
        <vs-popup :title="LabelConstant.popupTitleCreateNewKnowBusinessEntry" :active.sync="createKnownBusinessPopup"> 
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-6">{{ LabelConstant.infoLabelCreateKnownBusinessEntity }}</p>
                </div>
            </div>
            <template>
                <form data-vv-scope="createKnownBusinessForm">
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <span class="vs-input--label">{{ LabelConstant.inputLabelSelectMatchType }}</span>
                            <v-select
                                v-model="businessMatchType"
                                :reduce="matchTypeOptions => matchTypeOptions.value"
                                :label="'text'"
                                :options="matchTypeOptions"
                                :placeholder="LabelConstant.inputLabelSelectMatchType"
                                :clearable="false"
                            />
                            <span class="text-danger text-sm" v-if="businessMatchType == 'contains'">{{ LabelConstant.inputinfoLabelSelectMatchTypeMessage }}</span>  
                        </div>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" :label="LabelConstant.inputLabelCompanyName" v-model="businessCompanyName" name="businessCompanyName" v-validate="'required'"/>
                            <span class="text-danger text-sm">{{ errors.first('createKnownBusinessForm.businessCompanyName') }}</span>
                            <p class="ins-text">{{ LabelConstant.inputinfoLabelKnownBusiness }}</p>
                        </div>
                    </div>
                </form>
            </template>
            <div class="float-right mt-4 mb-8 w-full sm:w-auto">            
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="addBusinessEntry()">{{ LabelConstant.buttonLabelCreateKnownBusinessEntity }}</vs-button>
                <vs-button @click="cancelCreateNewBusiness()"  color="grey" type="border" class="ml-0 sm:ml-4 w-full sm:w-auto mt-2 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Delete Known Business Entry? -->
        <vs-popup class="delete-popup" :active.sync="deleteKnownBusinessPopupActive"> 
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full">
                        <div class="popup-icon"><feather-icon icon="Trash2Icon"></feather-icon></div>                    
                        <h4>{{ LabelConstant.popupHeadingLabelDeleteKnownBussinessEntry }}</h4>
                        <span class="deleted-no block">{{ deletableKnownBusiness }}</span>
                        <span class="deleted-no">{{ LabelConstant.inputLabelMatchType }}: {{ deletedKnownBusinessMatchType }}</span>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">            
                <vs-button color="danger" type="filled" @click="deleteBusiness()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="cancelDeletionBusiness()"  color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
        <!-- Create Known Business Entry Bulk -->
        <vs-popup :title="LabelConstant.popupTitleCreateNewKnowBusinessEntry" :active.sync="createBulkKnownBusinessPopup"> 
            <div class="vx-row">
                <div class="vx-col w-full">
                    <p class="mb-6">{{ LabelConstant.infoLabelCreateNewKnownISPEntriesInBulk }}</p>
                </div>
            </div>
            <template>
                <form data-vv-scope="createBulkKnownBusinessForm">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <span class="vs-input--label">{{ LabelConstant.inputLabelPleaseEnterKnownBusinesses }}</span>
                            <vs-textarea v-model="bulkBusinessCompanyNames" class="mb-0" name="bulkBusinessCompanyNames" v-validate="'required'"/>
                            <p class="ins-text">{{ LabelConstant.inputLabelPleaseEnterKnownBusinesses }}:</p>
                        </div>
                    </div>
                    <span class="text-danger text-sm">{{ errors.first('createBulkKnownBusinessForm.bulkBusinessCompanyNames') }}</span>
                </form>
            </template>
            <div class="float-right mt-4 mb-8 w-full sm:w-auto">            
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="addBusinessBulkEntry()">{{ LabelConstant.buttonLabelCreateKnownBusinessEntity }}</vs-button>
                <vs-button @click="cancelCreateNewBulkBusiness()"  color="grey" type="border" class="ml-0 sm:ml-4 w-full sm:w-auto mt-2 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>            
        </vs-popup>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
/* Filter */
import vSelect from 'vue-select'
import { Validator } from 'vee-validate';
const dict = {
    custom: {
        IspCompanyName: {
           required: 'The name must be more than 1 and less than 300 characters',
        },
        businessCompanyName: {
            required: 'The name must be more than 1 and less than 300 characters',
        },
        bulkIspCompanyNames: {
            required: 'The name must be more than 3 and less than 20,000 characters',
        },
        bulkBusinessCompanyNames: {
            required: 'The name must be more than 3 and less than 20,000 characters',
        }
    }
}
Validator.localize('en', dict);

export default {
    components: {
        VxCard,
        HelpModal,
        vSelect     
    },
    data() {
        return {
            helpPopupActive: false,
            
            knownISPEntityList: [],

            createKnownIpsPopup: false,
            IspCompanyName: null,
            IspMatchType: 'exact',

            createBulkKnownIpsPopup: false,
            bulkIspCompanyNames: null,

            deleteKnownIspPopupActive: false,
            deletableKnownIspId: null,
            deletableKnownIsp: null,
            deletedKnownIspMatchType: null,

            knownBusinessEntityList: [],

            createKnownBusinessPopup: false,
            businessCompanyName: null,
            businessMatchType: 'exact',

            createBulkKnownBusinessPopup: false,
            bulkBusinessCompanyNames: null,

            deleteKnownBusinessPopupActive: false,
            deletableKnownBusinessId: null,
            deletableKnownBusiness: null,
            deletedKnownBusinessMatchType: null,

            matchTypeOptions: [
                { text: "Exact Name Match", value: "exact" },
                { text: "Name Contains", value: "contains" },
            ],

            perPage: 5,
            recordList: [
                {text: '5', value: 5},
                {text: '10', value: 10},
                {text: '25', value: 25},
                {text: '50', value: 50},
                {text: '100', value: 100}
            ],
        }
    },
    created() {
        this.getISPList();
        this.getBusinessList();
    },
    methods: {
        /* Help Popup Close */
        closepopup() {
            (this.helpPopupActive = false)
        },
        getISPList(){
            this.$vs.loading();
            this.axios.get("/ws/ConfigFilterCompanies/GetISPList").then(response => {
                let data = response.data;
                this.knownISPEntityList = data
                this.knownISPEntityList.sort(this.GetSortOrder("ISP_Name", "ASC")); //Pass the attribute to be sorted on
                this.knownISPEntityList.map(function(manipulatedData) {
                    manipulatedData.sortableDate = new Date(manipulatedData.Date_Created);
                    return manipulatedData;
                });
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        cancelCreateNewISP(){
            this.createKnownIpsPopup = false
            this.IspCompanyName = null
            this.IspMatchType = 'exact'
            setTimeout(() => { 
                this.errors.clear('createKnownIpsForm')
            }, 20);
        },
        addISPEntry(){
            this.$validator.validateAll('createKnownIpsForm').then(result => {
                if (result) {
                    let addISPEntryParams = {
                        ISP_Name: this.IspCompanyName,
                        Match_Type: this.IspMatchType
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFilterCompanies/AddISPEntry",addISPEntryParams).then(() => {
                        this.getISPList()
                        this.cancelCreateNewISP()
                        this.$vs.notify({
                            title:'Success',
                            text: 'You have successfully added a known ISP entity. Please allow up to 24 hours for your account to reflect the change.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        cancelDeletionISP(){
            this.deleteKnownIspPopupActive=false
            this.deletableKnownIspId=null
            this.deletableKnownIsp=null
            this.deletedKnownIspMatchType=null    
        },
        deleteISP(){
            this.$vs.loading()
            this.axios.get("/ws/ConfigFilterCompanies/DeleteISP?id="+this.deletableKnownIspId).then(() => {
                this.$refs.tableDataISPEntity.searchx = null
                this.getISPList()
                this.cancelDeletionISP()
                this.$vs.notify({
                    title:'Success',
                    text: 'Company successfully removed from your known ISP entities.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        },
        getBusinessList(){
            this.$vs.loading();
            this.axios.get("/ws/ConfigFilterCompanies/GetBusinessList").then(response => {
                let data = response.data;
                this.knownBusinessEntityList = data
                this.knownBusinessEntityList.sort(this.GetSortOrder("ISP_Name", "ASC")); //Pass the attribute to be sorted on
                this.knownBusinessEntityList.map(function(manipulatedData) {
                    manipulatedData.sortableDate = new Date(manipulatedData.Date_Created);
                    return manipulatedData;
                });
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        cancelCreateNewBusiness(){
            this.createKnownBusinessPopup = false
            this.businessCompanyName = null
            this.businessMatchType = 'exact'
            setTimeout(() => { 
                this.errors.clear('createKnownBusinessForm')
            }, 20);
        },
        addBusinessEntry(){
            this.$validator.validateAll('createKnownBusinessForm').then(result => {
                if (result) {
                    let addBusinessEntryParams = {
                        ISP_Name: this.businessCompanyName,
                        Match_Type: this.businessMatchType
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFilterCompanies/AddBusinessEntry",addBusinessEntryParams).then(() => {
                        this.getBusinessList()
                        this.cancelCreateNewBusiness()
                        this.$vs.notify({
                            title:'Success',
                            text: 'You have successfully added a known business entity. Please allow up to 24 hours for your account to reflect the change.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        cancelDeletionBusiness(){
            this.deleteKnownBusinessPopupActive = false
            this.deletableKnownBusinessId = null
            this.deletableKnownBusiness = null
            this.deletedKnownBusinessMatchType = null
        },
        deleteBusiness(){
            this.$vs.loading()
            this.axios.get("/ws/ConfigFilterCompanies/DeleteISP?id="+this.deletableKnownBusinessId).then(() => {
                this.$refs.tableDataBusinessEntity.searchx = null
                this.getBusinessList()
                this.cancelDeletionBusiness()
                this.$vs.notify({
                    title:'Success',
                    text: 'Company successfully removed from your known business entities.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });
        },
        cancelCreateNewBulkISP(){
            this.createBulkKnownIpsPopup = false
            this.bulkIspCompanyNames = null
            setTimeout(() => { 
                this.errors.clear('createBulkKnownIpsForm')
            }, 20);    
        },
        addISPBulkEntry(){
            this.$validator.validateAll('createBulkKnownIpsForm').then(result => {
                if (result) {
                    let addISPBulkEntryParams = {
                        ISP_Name: this.bulkIspCompanyNames,
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFilterCompanies/AddISPBulkEntry",addISPBulkEntryParams).then(response => {
                        let data = response.data
                        this.getISPList()
                        this.cancelCreateNewBulkISP()
                        this.$vs.notify({
                            title:'Success',
                            text: data+' Please allow up to 24 hours for your account to reflect the change.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        cancelCreateNewBulkBusiness(){
            this.createBulkKnownBusinessPopup = false
            this.bulkBusinessCompanyNames = null
            setTimeout(() => { 
                this.errors.clear('createBulkKnownBusinessForm')
            }, 20);
        },
        addBusinessBulkEntry(){
            this.$validator.validateAll('createBulkKnownBusinessForm').then(result => {
                if (result) {
                    let addBusinessBulkEntryParams = {
                        ISP_Name: this.bulkBusinessCompanyNames,
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/ConfigFilterCompanies/AddBusinessBulkEntry",addBusinessBulkEntryParams).then(response => {
                        let data = response.data
                        this.getBusinessList()
                        this.cancelCreateNewBulkBusiness()
                        this.$vs.notify({
                            title:'Success',
                            text: data+' Please allow up to 24 hours for your account to reflect the change.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.$vs.loading.close()
                        this.showError(e);
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })   
        }
    },
    computed: {
		isTableRecordEntriesDisplay(){
			if(this.knownISPEntityList.length > 0){
				if(this.$refs.tableDataISPEntity.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
        isSearch: function () {
            if(this.knownISPEntityList.length > 0){
                if(this.$refs.tableDataISPEntity.searchx == null || this.$refs.tableDataISPEntity.searchx == ""){
                    return false
                }else{
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if(this.knownISPEntityList.length > 0){
                return ((this.$refs.tableDataISPEntity.currentx - 1) * this.perPage) + 1 
            }
        },
        showingDataTo: function () {
            if(this.knownISPEntityList.length > 0){
                let totalPages = this.$refs.tableDataISPEntity.getTotalPages
                if(totalPages == this.$refs.tableDataISPEntity.currentx){
                    return ((this.$refs.tableDataISPEntity.currentx - 1) * this.perPage) + this.$refs.tableDataISPEntity.datax.length
                }else{
                    return ((this.$refs.tableDataISPEntity.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if(this.knownISPEntityList.length > 0){
                return this.$refs.tableDataISPEntity.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if(this.knownISPEntityList.length > 0){
                let totalPages = this.$refs.tableDataISPEntity.getTotalPagesSearch
                if(totalPages == this.$refs.tableDataISPEntity.currentx){
                    return ((this.$refs.tableDataISPEntity.currentx - 1) * this.perPage) + this.$refs.tableDataISPEntity.datax.length
                }else{
                    return ((this.$refs.tableDataISPEntity.currentx) * this.perPage)
                }
            }
        },

        // isTableRecordEntriesDisplayExclusionList(){
        //     if(this.knownBusinessEntityList.length > 0){
        //         if(this.$refs.tableDataBusinessEntity.datax.length > 0){
        //             return true
        //         }else{
        //             return false
        //         }
        //     }else{
        //         return false
        //     }
        // },
        // isSearchExclusionList: function () {
        //     if(this.knownBusinessEntityList.length > 0){
        //         if(this.$refs.tableDataBusinessEntity.searchx == null || this.$refs.tableDataBusinessEntity.searchx == ""){
        //             return false
        //         }else{
        //             return true
        //         }
        //     }
        // },
        // showingDataFromExclusionList: function () {
        //     if(this.knownBusinessEntityList.length > 0){
        //         return ((this.$refs.tableDataBusinessEntity.currentx - 1) * this.perPage) + 1 
        //     }
        // },
        // showingDataToExclusionList: function () {
        //     if(this.knownBusinessEntityList.length > 0){
        //         let totalPages = this.$refs.tableDataBusinessEntity.getTotalPages
        //         if(totalPages == this.$refs.tableDataBusinessEntity.currentx){
        //             return ((this.$refs.tableDataBusinessEntity.currentx - 1) * this.perPage) + this.$refs.tableDataBusinessEntity.datax.length
        //         }else{
        //             return ((this.$refs.tableDataBusinessEntity.currentx) * this.perPage)
        //         }
        //     }
        // },
        // totalSearchedDataExclusionList: function () {
        //     if(this.knownBusinessEntityList.length > 0){
        //         return this.$refs.tableDataBusinessEntity.queriedResults.length
        //     }
        // },
        // showingSearchedDataToExclusionList: function () {
        //     if(this.knownBusinessEntityList.length > 0){
        //         let totalPages = this.$refs.tableDataBusinessEntity.getTotalPagesSearch
        //         if(totalPages == this.$refs.tableDataBusinessEntity.currentx){
        //             return ((this.$refs.tableDataBusinessEntity.currentx - 1) * this.perPage) + this.$refs.tableDataBusinessEntity.datax.length
        //         }else{
        //             return ((this.$refs.tableDataBusinessEntity.currentx) * this.perPage)
        //         }
        //     }
        // }
    }
};
</script>
